import React, { useEffect, useState } from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/SEO';
import Cta from '@components/content/Cta';
import { KfHeroUtility,KfAnchorLink, isMobile, debounce } from '@klickinc/kf-react-components';
import Loadable from '@loadable/component';
import Image from '@components/utility/Image';
import { Link } from "gatsby"
// Style import
import './styles.scss';

const Switch = Loadable(() => import('@components/content/switch/Switch'));
const InViewport = Loadable(() => import('@components/utility/InViewport'));

const Index = () => {
	const [mobile, setMobile] = useState(false);
	const mobileHandler = () => {
		setMobile(isMobile());
	};
	useEffect(() => {
		const mobileCheck = debounce(mobileHandler, 18);
		mobileCheck();
		if (typeof window !== 'undefined') {
			document.addEventListener('resize', mobileCheck);
		}

		return () => {
			document.removeEventListener('resize', mobileCheck);
		};
    },[])

	return (
		<Layout>
			<Seo title='EMFLAZA® (deflazacort) | For Healthcare Professionals' description='EMFLAZA® (deflazacort) is the first & only FDA-approved corticosteroid for boys with Duchenne muscular dystrophy as young as age 2. See efficacy and safety data and view full Prescribing Information & Important Safety Information.' />
			<div className='page--home'>
				<section className='hero'>
					<div className='relative max-w-full lg:absolute'>
						<Image imageName='hero.png' imageNameMobile="hero-mobile.png" alt="" objectFit='cover' />
						<span className='absolute right-[5vw] lg:right-[33vw] xl:right-[30vw] patient-image-alt 2xl:right-[33vw] 2.5xl:right-[35vw] 3xl:right-[35vw]'>Not actual patients.</span>
					</div>
					<div className='container absolute top-0 pt-10 md:pt-36 lg:relative lg:pt-12 xl:pt-[88px] lg:pb-[16%] xl:pb-[10%] 2xl:pb-[16%] 3xl:pt-40'>
						<KfHeroUtility addedClass="heading heading-hero max-w-[483px]">
							<span>Acting like a</span> <span>6&#8209;year&#8209;old</span> <span>because</span> <span>he&nbsp;can<sup>1,2</sup></span>
						</KfHeroUtility>

						<p className='text-base xl:text-lg xl:leading-snug max-w-[284px] lg:max-w-[400px] xl:max-w-[450px] my-4 lg:mt-6 lg:mb-5 xl:my-4 desktop:mb-5 desktop:mt-6'>Help him here and now with EMFLAZA:<br /> <span className="italic font-black uppercase highlightedText">the first</span> FDA-approved corticosteroid for boys with Duchenne muscular dystrophy (DMD) <br className='hidden xl:inline' /> as young as age 2.<sup className='text-xxs'>3,4</sup></p>
						<p className='text-xl xl:text-4xl font-black text-white font-gotham max-w-[483px]'>
							<span className='inline-block leading-5 lg:leading-8'>
								THAT'S <span className='italic text-emflaza-blue-600'>DMD <br className='inline lg:hidden'/> REVOLUTIONARY</span>.
							</span>
						</p>
						<Link to='/efficacy/muscle-function' className="mt-4 btn lg:mt-6">See the Data</Link>
					</div>
				</section>

				<section className='relative transform -translate-y-16 md:-translate-y-36 lg:-translate-y-16 xl:transform-none'>
					<div className='hidden lg:block absolute left-[-110px] max-w-[520px] xl:max-w-[596px] 2xl:max-w-[750px] 2xl:top-12 2.5xl:max-w-[850px] 3xl:top-48'>
						<Image imageName='blue-cutout.png' alt=""></Image>
					</div>
					<div className='lg:hidden absolute left-0 max-w-[340px]'>
						<Image imageName='blue-cutout-mobile.png' alt=""></Image>
					</div>
					<div className='container relative md:flex md:items-center pt-24 lg:pt-20 2xl:pt-60 3xl:pt-[21rem] 3xl:pb-44'>
						<div className='md:max-w-[400px] lg:mr-28 3xl:min-h-[650px]'>
							<h2 className='mb-3 text-xl font-black text-white uppercase lg:text-4xl max-w-[70%] lg:max-w-full'>Supporting his&nbsp;functional &&nbsp;emotional needs<sup className='superscript'>5-7</sup></h2>
							<p className='text-base max-w-[267px] lg:max-w-[302px]'>A boy's physical and emotional stability are important considerations for DMD treatment. Find out how corticosteroids can have an impact.<sup className='text-xxs'>7</sup></p>
							<div className='bg-cooking-8yo'>
								<InViewport delay={500} disable={mobile} addedClass='inViewport--image'>
									<Image imageName='home-not-actual-patient.png' alt="Cooking like an 8-year-old"></Image>
									<span className='absolute right-0 desktop:-right-8 patient-image-alt top-[70%] 2.5xl:-right-8 3xl:right-16'>Not an actual <br/>patient.</span>
								</InViewport>
							</div>
						</div>
						<div className='mt-20 xs:mt-10 lg:mt-0 lg:max-w-[440px] xl:mr-isi'>
							<Cta ctaUrl='/efficacy/real-world-data' iconUrl={'cta-world-icon-2.png'} ctaText='Real-World Data' addedClass='cta mb-6' iconAddedClass='max-w-[63px]'/>
							<Cta ctaUrl='/tolerability/weight-behavior' iconUrl={'cta-person-icon.png'} ctaText='Effects on weight & behavior' addedClass='cta' iconAddedClass='max-w-[67px]'/>
						</div>
					</div>
				</section>

				<section className='relative bg-prescribing mb-9 lg:mb-16 2.5xl:mt-32 3xl:mt-0 prescription-quickstart'>
					<div className='hidden lg:block absolute left-[-244px] max-w-[1526px] 2.5xl:max-w-[1600px] 2.5xl:left-[-264px]'>
						<InViewport delay={500} disable={mobile} addedClass='inViewport--image'>
							<Image imageName='light-blue-bg.png' alt=""></Image>
						</InViewport>
					</div>
					<div className='container relative pb-16 md:flex pt-28 md:pt-40 lg:pt-28 md:justify-end md:items-center'>
						<div className='max-w-30 md:max-w-[150px] md:mr-[94px] mx-auto'>
							<Image imageName='rx-icon.png' alt=""></Image>
						</div>

						<div className='border-t-2 md:border-l-2 md:border-t-0 border-opacity-50 border-emflaza-blue-600 lg:max-w-[526px] lg:mr-isi md:pl-[86px] pt-9 md:pt-0 mt-7 md:mt-0'>
							<h2 className='mb-4 text-xl font-black uppercase lg:mb-6 xl:text-4xl font-gotham'>Prescribing in 3&nbsp;quick&nbsp;steps</h2>
							<p className='max-w-[332px]'>Help patients get started with EMFLAZA in 3 quick steps using the Prescription Start Form.</p>

							<div className='flex items-center mt-5 mb-6 lg:items-end lg:mt-2'>
								<div className='max-w-[44px] mr-3'>
									<Image imageName='download.png' alt=""></Image>
								</div>
								<KfAnchorLink url='/prescription-start-form.pdf' target="_blank" linkClass='link'>Download the form</KfAnchorLink>
							</div>
							<Link to='/getting-started/prescription-start-form' className='block my-0 btn w-[244px]'>Get Started</Link>
						</div>
					</div>
				</section>

				<section className='pb-20 switch-section bg-emflaza-blue-25 lg:pb-24'>
					<div className='container lg:flex lg:justify-end'>
						<div className='lg:sticky lg:top-0 lg:h-full'>
							<div className='relative right-10 lg:right-auto lg:left-0 switch-patient-image'>
								<div>
									<Image imageName='home-emflaza-patient.png' alt=""></Image>
									<span className='absolute patient-image-alt right-7 bottom-8 md:right-48 md:bottom-20 lg:right-12 lg:bottom-4 xl:bottom-16 xl:right-36 2xl:right-64 2.5xl:right-96 3xl:right-96'>Actual EMFLAZA patient.</span>
								</div>
							</div>
						</div>
						<div className='lg:max-w-[440px] lg:mr-isi lg:pt-16'>
							<h2 className='mb-4 text-xl uppercase lg:mb-6 lg:text-4xl heading heading-darkblue'>Considering a Corticosteroid switch?</h2>
							<p className='lg:mr-20'>There are several factors to consider in DMD treatment. Help parents better understand the situation with S.W.I.T.C.H. considerations.</p>
							<Switch></Switch>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	)
}


export default Index;
